@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
html {
  font-family: 'Inter', Roboto;
  height: 100vh;
  --scrollbarBG: #e2e2f800;
  --thumbBG: #e2e2f8;
}

body {
  margin: 0;
  font-family: 'Inter', Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  /* scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG); */
}

/* body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
